.popup-container {
  width: 100vw;
  padding: 10px;
  height: 100%;
  overflow-y: scroll;
  font-size: 14px;
  margin: 0 auto;
  background: #fff;
  position: fixed;
  z-index: 999;
  padding-left: 10%;
  padding-right: 10%;
  color: #0b6298;
  overflow-x: hidden;
}

h1 {
  margin-bottom: 35px;
}

.popup-content {
  width: 100%;
  margin: 0 auto;
}

.request-image {
  width: 100vw;
  position: relative;
  height: 20vh;
  background-size: cover;
  margin-top: 84px;
  margin-bottom: 50px;
  left: -13%;
}

.back-button-container {
  padding: 5px;
  width: 90px;
  border: 1px solid #0b6298;
  text-align: center;
  font-size: 16px;
  border-radius: 3px;
  color: #0b6298;
  margin-bottom: 30px;
  cursor: pointer;
  display: block;
  text-align: center;
}

.requestademo .back-button-container {
  position: fixed;
  top: 30px;
  left: 20px;
  color: white;
  z-index: 10;
  border-color: white;
}

.back-button-container:hover {
  background: #0b6298;
  color: #ffffff;
}

/* scrollbar */
.popup-container::-webkit-scrollbar {
    width: 10px;
}
.popup-container::-webkit-scrollbar-track {
    background: #CCCCCC;
}
.popup-container::-webkit-scrollbar-thumb {
    background: #0b6298;
    border-radius: 10px;
}

.link-view{
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

@media only screen
and (max-width: 767px) {
  .popup-container {
    width: 100%;
    padding: 10px;
    height: 100%;
  }
  .request-image {
    height: 10vh;
    left: -5%;
  }
  .requestademo .back-button-container{
    top: 49px;
  }
}
