.cookie-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #0b6298;
    color: #fff;
    z-index: 2;
}

.cookie-container > p {
    margin-bottom: 0;
    margin-right: 20px;
}

.cookie-container > span {
    margin-right: 20px;
}

.footer-link {
    color: #FFF;
    border: none;
    border-radius: 5px;
    background: #0b6298;
}

.footer-link:hover {
    color: #3FA7D6;
}

.btn {
    background: #3f9dc7;
}

.submit-button {
    background: #0b6298 !important
}

.submit-button-border {
    border-color: #0b6298 !important
}

.submit-button-color {
    color: #0b6298 !important
}

@media only screen
and (max-width: 767px) {
    .cookie-container{
        flex-direction: column;
        height: 150px;
    }
}

@media only screen
and (min-width: 768px)
and (max-width: 1200px) {
    .cookie-container{
        flex-direction: column;
        height: 150px;
    }
}
